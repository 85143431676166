import { QUERY_AIRPLAY_DEVICES } from "@/app/gql/devices/devices_queries";
import useMGQuery from "@/app/utils/gql/useMGQuery";
import UserUtils from "@/app/utils/users/user_utils";
import { useMemo } from "react";
import MirrorDeviceModal from "../components/MirrorDeviceModal";
import { useMirrorDeviceMutation } from "./useMirrorDeviceMutation";

export const MirrorDeviceModalContainer = ({
  device_uuid,
  onMirrorDeviceSuccess,
  onMirrorDeviceFailure,
  onMirrorDeviceCancel,
}) => {
  const { mirrorDeviceSubmit } = useMirrorDeviceMutation({
    device_uuid,
  });

  const currentUser = UserUtils.getCurrentSchoolUser();

  const onSubmitHandler = async (values, formik) => {
    try {
      await mirrorDeviceSubmit(values, formik);
      if (typeof onMirrorDeviceSuccess === "function") onMirrorDeviceSuccess();
    } catch (e) {
      onMirrorDeviceFailureHandler(e);
    }
  };

  const { data: airplayDevicesData } = useMGQuery(QUERY_AIRPLAY_DEVICES, {
    variables: {
      schoolId: currentUser.school_id,
      page: 1,
      limit: 1000,
    },
  });

  //Memoize response from GQL
  const airPlayDevices = useMemo(() => {
    return airplayDevicesData?.airPlayDevices?.edges
      ? airplayDevicesData?.airPlayDevices?.edges?.map(({ node }) => {
          let newNode = { ...node, meta: JSON.parse(node.meta_data) };
          return newNode;
        })
      : [];
  }, [airplayDevicesData]);

  const onMirrorDeviceFailureHandler = (err) => {
    if (typeof onMirrorDeviceFailure === "function") onMirrorDeviceFailure(err);
  };

  const onMirrorDeviceCancelHandler = () => {
    if (typeof onMirrorDeviceCancel === "function") onMirrorDeviceCancel();
  };

  return (
    <>
      <MirrorDeviceModal
        isShowing={device_uuid}
        onClose={onMirrorDeviceCancelHandler}
        onSubmit={onSubmitHandler}
        devices={airPlayDevices}
      />
    </>
  );
};
