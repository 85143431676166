import { useMemo } from "react";
import EyesUpModal from "../components/EyesUpModal";
import { useEyesUpMutation } from "./useEyesUpMutation";

export const EyesUpModalContainer = ({ device, classId, onEyesUpSuccess, onEyesUpFailure, onCancel }) => {
  const { eyesUpLoading, eyesUpSubmit } = useEyesUpMutation({
    device,
    classId,
  });

  const eyesUpDevices = useMemo(() => {
    return device?.filter((d) => d.class_device_status?.isHeadsUp) ?? [];
  }, [device]);

  const eyesDownDevices = useMemo(() => {
    return device?.filter((d) => !d.class_device_status?.isHeadsUp) ?? [];
  }, [device]);

  const onCancelHandler = () => {
    if (typeof onCancel === "function") onCancel();
  };

  const onSubmitHandler = async (status) => {
    try {
      await eyesUpSubmit(status);
      if (typeof onEyesUpSuccess === "function") onEyesUpSuccess(status);
    } catch (e) {
      onSubmitFailureHandler(e);
    }
  };

  const onSubmitFailureHandler = (err) => {
    if (typeof onEyesUpFailure === "function") onEyesUpFailure(err);
  };

  return (
    <>
      <EyesUpModal
        isShowing={device && device.length > 0}
        eyesUpDevices={eyesUpDevices}
        eyesDownDevices={eyesDownDevices}
        eyesUpLoading={eyesUpLoading}
        onClose={onCancelHandler}
        onSuccess={onSubmitHandler}
      />
    </>
  );
};
