// import { MGSwitch } from "@/components/controls";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { Form, Formik } from "formik";
import { MGButton } from "@/components/buttons";
import { MGHeading3 } from "@/components/typography";
import { MGDropdownField, MGDropdownItem } from "@/components/inputs";
import { logger } from "@/app/services/logging/Logger";

export const BluetoothModal = ({ isShowing, initialValues, onClose, onSubmit }) => {
  const { t } = useTranslation();

  const bluetoothOptions = [
    { value: 1, content: t("Force On") },
    { value: 0, content: t("Force Off") },
  ];

  const validationSchema = yup.object().shape({
    bluetooth_option: yup.number().required(t("Required")),
    // bluetooth_option: yup.lazy((value) =>
    //   typeof value === "boolean"
    //     ? yup.boolean().required("Required field").typeError("Required field") // typeError is necessary here, otherwise we get a bad-looking yup error
    //     : yup.number().required("Required field")
    // ),
  });

  const onSubmitHandler = (values, formik) => {
    if (typeof onSubmit === "function") {
      onSubmit(values, formik);
    } else {
      logger.debug("BluetoothModal: no 'onSubmit' prop provided to Modal, cannot change bluetooth status.");
    }
  };

  return (
    <>
      <MGModal isShowing={isShowing}>
        <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitHandler}>
          {(props) => {
            const { dirty, isSubmitting, isValid, submitForm } = props;
            return (
              <>
                <MGModalHeader>
                  <MGHeading3>{t("Bluetooth Setting")}</MGHeading3>
                </MGModalHeader>
                <MGModalBody>
                  <Form>
                    <MGDropdownField name="bluetooth_option" placeholder={t("Select Status")}>
                      {bluetoothOptions &&
                        bluetoothOptions?.map((item, idx) => (
                          <MGDropdownItem key={idx} value={item.value} displayValue={item.content}>
                            {item.content}
                          </MGDropdownItem>
                        ))}
                      <MGDropdownItem key={-1} value={-1}>
                        {t("Device default")}
                      </MGDropdownItem>
                    </MGDropdownField>
                  </Form>
                </MGModalBody>
                <MGModalFooter>
                  <MGButton variant="tertiary" onClick={onClose}>
                    {t("Cancel")}
                  </MGButton>
                  <MGButton
                    variant="primary"
                    isLoading={isSubmitting}
                    disabled={!dirty || !isValid}
                    onClick={submitForm}
                  >
                    {t("Apply Setting")}
                  </MGButton>
                </MGModalFooter>
              </>
            );
          }}
        </Formik>
      </MGModal>
    </>
  );
};

export default BluetoothModal;
