import "./style.css";

export const MGTabItem = ({ active, tabClick, children }) => {
  return (
    <li className="--mg-tab-item" onClick={tabClick}>
      <div className={active ? "active" : ""}>{children}</div>
    </li>
  );
};

MGTabItem.propTypes = {};

MGTabItem.defaultProps = {};

export default MGTabItem;
