import { Form, Formik } from "formik";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGHeading3, MGLabel } from "@/components/typography";
import { MGButton } from "@/components/buttons";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { MGDropdownField, MGDropdownItem } from "@/components/inputs";
import { logger } from "@/app/services/logging/Logger";

const MirrorDeviceModal = (props) => {
  const { isShowing, initialValues, onClose, onSubmit, devices } = props;

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    resource_id: yup.string().required(t("Required")),
  });

  const onSubmitHandler = (values, formik) => {
    if (typeof onSubmit === "function") {
      onSubmit(values, formik);
    } else {
      logger.debug("MirrorDeviceModal: no 'onSubmit' prop provided to Modal, cannot mirror device.");
    }
  };

  return (
    <MGModal isShowing={isShowing}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitHandler}>
        {(props) => {
          const { dirty, isSubmitting, isValid, submitForm } = props;
          return (
            <>
              <MGModalHeader>
                <MGHeading3>{t("Mirror Device")}</MGHeading3>
              </MGModalHeader>
              <MGModalBody>
                <Form>
                  <MGLabel>{t("Select device")}</MGLabel>
                  <MGDropdownField name="resource_id" placeholder={t("Select Device")}>
                    {devices &&
                      devices?.map((item, idx) => (
                        <MGDropdownItem key={idx} value={item.id} displayValue={item.meta?.Name}>
                          {item.meta?.Name}
                        </MGDropdownItem>
                      ))}
                  </MGDropdownField>
                </Form>
              </MGModalBody>
              <MGModalFooter>
                <MGButton variant="tertiary" onClick={onClose}>
                  {t("Cancel")}
                </MGButton>
                <MGButton variant="primary" isLoading={isSubmitting} disabled={!dirty || !isValid} onClick={submitForm}>
                  {t("Mirror Screen")}
                </MGButton>
              </MGModalFooter>
            </>
          );
        }}
      </Formik>
    </MGModal>
  );
};

export default MirrorDeviceModal;
