import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { MUTATION_DEVICE_ACTION_ENABLE_LOST_MODE } from "@/app/gql/devices/device_actions_mutations";
import { useTranslation } from "react-i18next";
import useMGMutation from "@/app/utils/gql/useMGMutation";

export const useDeviceActionEnableLostMode = () => {
  const [notification] = useNotification();

  const { t } = useTranslation();

  const [deviceActionEnableLostModeMutation] = useMGMutation(MUTATION_DEVICE_ACTION_ENABLE_LOST_MODE);
  const deviceActionEnableLostMode = async (devices, lostmessage, lostnumber, lostfootnote) => {
    const { data } = await deviceActionEnableLostModeMutation({
      variables: {
        input: {
          device_uuids: devices.map((d) => d.device_uuid),
          lostmessage,
          lostnumber,
          lostfootnote,
        },
      },
    });

    if (data && data.deviceActionEnableLostMode) {
      let { data: response, errors } = data.deviceActionEnableLostMode;
      if (errors) {
        notification.error({
          message:
            devices.length > 1
              ? t("Failed to enable lost mode for {{%s}} devices", { "%s": devices.length })
              : t("Failed to enable lost mode for device"),
        });
      }

      if (response.handled) {
        notification.success({
          message:
            response.handled.length > 1
              ? t("Lost mode enabled for {{%s}} devices", { "%s": response.handled.length })
              : t("Lost mode enabled for device"),
          tiny: true,
        });
      }
    }
  };

  return [deviceActionEnableLostMode];
};
