import SendMessageModal from "../components/SendMessageModal";
import { useSendMessageMutation } from "./useSendMessageMutation";

export const SendMessageModalContainer = ({ device_uuids, onSendMessageSuccess, onSendMessageFailure, onCancel }) => {
  const { sendMessageSubmit } = useSendMessageMutation({
    device_uuids,
  });

  const onSubmitHandler = async (values, formik) => {
    try {
      await sendMessageSubmit(values, formik);
      if (typeof onSendMessageSuccess === "function") onSendMessageSuccess();
    } catch (e) {
      onSendMessageFailureHandler(e);
    }
  };

  const onSendMessageFailureHandler = (err) => {
    if (typeof onSendMessageFailure === "function") onSendMessageFailure(err);
  };

  const onCancelHandler = () => {
    if (typeof onCancel === "function") onCancel();
  };

  return (
    <>
      <SendMessageModal
        isShowing={device_uuids && device_uuids.length > 0}
        initialValues={{
          message: "",
        }}
        onClose={onCancelHandler}
        onSubmit={onSubmitHandler}
      />
    </>
  );
};
