import { EmptyState } from "@/app/app-components/EmptyState/EmptyState";
import { MGButton } from "@/components/buttons";
import { MGCheckboxField } from "@/components/controls";
import { MGFlexBox } from "@/components/layout";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGProgressSpinner } from "@/components/progress";
import { MGSpacing } from "@/components/spacing";
import { MGTabItem, MGTabs } from "@/components/tabs";
import { MGHeading3, MGHelperText } from "@/components/typography";
import { Form, Formik } from "formik";
import { useTranslation } from "react-i18next";

export const PushContentModal = ({
  isShowing,
  classModeId,
  initialValues,
  classContentLoading,
  contentItems,
  onClose,
  onSubmit,

  showingModeContent,
  onSwitchContentButtonClick,
}) => {
  const { t } = useTranslation();

  const onCloseHandler = () => {
    if (typeof onClose === "function") onClose();
  };

  const onSubmitHandler = (values, formik) => {
    for (const [key, value] of Object.entries(values)) {
      if (value == false) {
        delete values[key];
      }
    }
    if (typeof onSubmit === "function") onSubmit(values, formik);
  };

  const onSwitchContentButtonClickHandler = (value) => {
    if (typeof onSwitchContentButtonClick === "function") {
      onSwitchContentButtonClick(value);
    }
  };

  const emptyStateMessage = showingModeContent
    ? t("No content has been configured for this class mode")
    : t("No content has been configured for this class");

  return (
    <>
      <MGModal isShowing={isShowing} onClose={onCloseHandler} closable={false}>
        {classContentLoading && <MGProgressSpinner isLarge={true} />}
        {!classContentLoading && (
          <Formik initialValues={initialValues} onSubmit={onSubmitHandler}>
            {(props) => {
              const { dirty, isSubmitting, isValid, submitForm, resetForm } = props;
              return (
                <>
                  <MGModalHeader>
                    <MGFlexBox>
                      <MGHeading3>{t("Push Content")}</MGHeading3>
                    </MGFlexBox>
                  </MGModalHeader>

                  <MGModalBody>
                    <Form>
                      {!classContentLoading && classModeId && (
                        <>
                          <br />
                          <MGFlexBox>
                            <MGTabs>
                              <MGTabItem
                                active={showingModeContent}
                                tabClick={() => {
                                  resetForm();
                                  onSwitchContentButtonClickHandler(true);
                                }}
                              >
                                {t("Mode Content")}
                              </MGTabItem>
                              <MGTabItem
                                active={!showingModeContent}
                                tabClick={() => {
                                  resetForm();
                                  onSwitchContentButtonClickHandler(false);
                                }}
                              >
                                {t("Class Content")}
                              </MGTabItem>
                            </MGTabs>
                          </MGFlexBox>
                          <br />
                        </>
                      )}

                      <div style={{ maxHeight: "40vh", overflowY: "scroll" }}>
                        {classContentLoading && (
                          <>
                            <div style={{ textAlign: "center", overflowY: "hidden" }}>
                              <MGProgressSpinner isLarge={true} />
                            </div>
                          </>
                        )}
                        {!classContentLoading &&
                          contentItems &&
                          contentItems.map((item) => {
                            let trimmedDetail = item?.detail;
                            if (trimmedDetail) {
                              if (trimmedDetail && trimmedDetail.length > 70)
                                trimmedDetail = trimmedDetail.substring(0, 67) + "...";
                            }

                            return (
                              <div
                                key={item.contentId}
                                style={{ padding: "10px", maxHeight: "250px", overflowY: "scroll" }}
                              >
                                <MGCheckboxField name={item.contentId}>
                                  {item.name}
                                  <MGHelperText>{trimmedDetail}</MGHelperText>
                                </MGCheckboxField>
                              </div>
                            );
                          })}
                        {!classContentLoading && contentItems?.length < 1 && (
                          <>
                            <EmptyState message={emptyStateMessage} />
                          </>
                        )}
                      </div>
                    </Form>
                  </MGModalBody>

                  <MGModalFooter>
                    <MGButton variant={"secondary"} onClick={onCloseHandler}>
                      {t("Cancel")}
                    </MGButton>
                    <MGSpacing size="md" />
                    <MGButton
                      variant={"primary"}
                      disabled={!dirty || !isValid}
                      isLoading={isSubmitting}
                      onClick={submitForm}
                    >
                      {t("Push Content")}
                    </MGButton>
                    {/* <pre><code>{JSON.stringify(values, null, 2)}</code></pre> */}
                  </MGModalFooter>
                </>
              );
            }}
          </Formik>
        )}
      </MGModal>
    </>
  );
};
