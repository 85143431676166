import BluetoothModal from "../components/BluetoothModal";
import { useBluetoothMutation } from "./useBluetoothMutation";

export const BluetoothModalContainer = ({
  device_uuids,
  onBluetoothSuccess,
  onBluetoothFailure,
  onBluetoothCancel,
}) => {
  const { bluetoothSubmit } = useBluetoothMutation({
    device_uuids,
  });

  const onSubmitHandler = async (values) => {
    try {
      await bluetoothSubmit(values);
      if (typeof onBluetoothSuccess === "function") onBluetoothSuccess();
    } catch (e) {
      onBluetoothFailureHandler(e);
    }
  };

  const onBluetoothFailureHandler = (err) => {
    if (typeof onBluetoothFailure === "function") onBluetoothFailure(err);
  };

  const onBluetoothCancelHandler = () => {
    if (typeof onBluetoothCancel === "function") onBluetoothCancel();
  };

  return (
    <>
      <BluetoothModal
        isShowing={device_uuids}
        initialValues={{}}
        onClose={onBluetoothCancelHandler}
        onSubmit={onSubmitHandler}
      />
    </>
  );
};
