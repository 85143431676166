import { useDeviceActionLockWithPin } from "../../hooks/useDeviceActionLockWithPin";
import LockWithPinModal from "../components/LockWithPinModal";
import PropTypes from "prop-types";

export const LockWithPinModalContainer = ({ devices, onLockWithPinSuccess, onLockWithPinFailure, onCancel }) => {
  const [deviceActionLockWithPin] = useDeviceActionLockWithPin();

  const onSubmitHandler = async (values) => {
    try {
      const { data } = await deviceActionLockWithPin(devices, values.pin);
      if (typeof onLockWithPinSuccess === "function") onLockWithPinSuccess(data);
    } catch (e) {
      onSendMessageFailureHandler(e);
    }
  };

  const onSendMessageFailureHandler = (err) => {
    if (typeof onLockWithPinFailure === "function") onLockWithPinFailure(err);
  };

  const onCancelHandler = () => {
    if (typeof onCancel === "function") onCancel();
  };

  return (
    <>
      <LockWithPinModal
        isShowing={devices && devices.length > 0}
        initialValues={{
          pin: "",
        }}
        onClose={onCancelHandler}
        onSubmit={onSubmitHandler}
      />
    </>
  );
};

LockWithPinModalContainer.propTypes = {
  devices: PropTypes.array,
  onLockWithPinSuccess: PropTypes.func,
  onLockWithPinFailure: PropTypes.func,
  onCancel: PropTypes.func,
};
