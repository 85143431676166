import { MUTATION_DEVICE_ACTION_START_MIRROR } from "@/app/gql/devices/device_actions_mutations";
import useMGMutation from "@/app/utils/gql/useMGMutation";

export const useMirrorDeviceMutation = ({ device_uuid }) => {
  const [mirrorDeviceMutation] = useMGMutation(MUTATION_DEVICE_ACTION_START_MIRROR);

  const mirrorDeviceSubmit = async (values, formik) => {
    formik.setSubmitting(true);

    let mirrorDevicePayload = {
      device_uuid: "",
      resource_id: "",
    };

    mirrorDevicePayload.device_uuid = device_uuid;
    mirrorDevicePayload.resource_id = values.resource_id;
    const response = await mirrorDeviceMutation({
      variables: {
        input: {
          device_uuid: device_uuid,
          resource_id: values.resource_id,
        },
      },
      onError: (data) => {
        formik.setSubmitting(false);
        throw data;
      },
    });
    formik.setSubmitting(false);
    return response;
  };

  return {
    mirrorDeviceSubmit: mirrorDeviceSubmit,
  };
};
