import PropTypes from "prop-types";
import RemoveTagsModal from "../components/RemoveTagsModal";
import { useRemoveTagsMutation } from "./useRemoveTagsMutation";

export const RemoveTagsModalContainer = ({ devices, onRemoveTagsSuccess, onRemoveTagsFailure, onRemoveTagsCancel }) => {
  const { deviceActionRemoveTags } = useRemoveTagsMutation({
    devices,
  });

  const onSubmitHandler = async (values) => {
    try {
      let tags = [];
      values.tags.map((t) => tags.push(t.key));
      await deviceActionRemoveTags(devices, tags);
      if (typeof onRemoveTagsSuccess === "function") onRemoveTagsSuccess();
    } catch (e) {
      onRemoveTagsFailureHandler(e);
    }
  };

  const onRemoveTagsFailureHandler = (err) => {
    if (typeof onRemoveTagsFailure === "function") onRemoveTagsFailure(err);
  };

  const onRemoveTagsCancelHandler = () => {
    if (typeof onRemoveTagsCancel === "function") onRemoveTagsCancel();
  };

  return (
    <>
      <RemoveTagsModal
        isShowing={devices && devices.length > 0}
        initialValues={{
          tags: "",
        }}
        onClose={onRemoveTagsCancelHandler}
        onSubmit={onSubmitHandler}
      />
    </>
  );
};

RemoveTagsModalContainer.propTypes = {
  devices: PropTypes.array,
  onRemoveTagsSuccess: PropTypes.func,
  onRemoveTagsFailure: PropTypes.func,
  onRemoveTagsCancel: PropTypes.func,
};
