import { MUTATION_DEVICE_ACTION_PUSH_CONTENT } from "@/app/gql/devices/device_actions_mutations";
import { CLASS_CONTENTS_ACTIVE_CLASS_QUERY } from "@/app/gql/classes/active-class-queries/class_contents_active_class";
import useMGLazyQuery from "@/app/utils/gql/useLazyMGQuery";
import { QUERY_CLASS_MODE_CONTENTS } from "@/app/gql/modes/modes_queries";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import useMGQuery from "@/app/utils/gql/useMGQuery";

export const usePushContentModalContainer = ({ classId, classModeId, sessionId }) => {
  const [pushContentMutation] = useMGMutation(MUTATION_DEVICE_ACTION_PUSH_CONTENT);
  const [getClassModeContent] = useMGLazyQuery(QUERY_CLASS_MODE_CONTENTS);

  // debugger;

  const { loading, error, data } = useMGQuery(CLASS_CONTENTS_ACTIVE_CLASS_QUERY, {
    variables: {
      classId: classId,
      sessionId: sessionId,
    },
    notifyOnNetworkStatusChange: false,
  });

  const classContent = data?.classContentsActiveClass?.edges;

  const getClassModeContentData = async ({ classModeId }) => {
    const response = await getClassModeContent({
      variables: {
        classModeId: classModeId,
        limit: 1000,
        page: 1,
      },
    });

    return response;
  };

  const pushContentSubmit = async (values, formik) => {
    formik.setSubmitting(true);
    let pushContentPayload = {
      deviceUUIDs: values.device_uuids,
      classId: classId,
      classContentIds: sanitizeClassContentIds(values),
    };

    const response = await pushContentMutation({
      variables: {
        input: pushContentPayload,
      },
      onError: (data) => {
        formik.setSubmitting(false);
        throw data;
      },
    });

    formik.setSubmitting(false);
    return response;
  };

  const pushModeContentSubmit = async (values, formik) => {
    formik.setSubmitting(true);

    let pushContentPayload = {
      deviceUUIDs: values.device_uuids,
      classId: classId,
      classModeId: classModeId,
      classContentIds: sanitizeClassContentIds(values),
    };

    const response = await pushContentMutation({
      variables: {
        input: pushContentPayload,
      },
      onError: (data) => {
        formik.setSubmitting(false);
        throw data;
      },
    });

    formik.setSubmitting(false);
    return response;
  };

  return {
    classContent: classContent,
    classContentLoading: loading,
    classContentError: error,
    pushContentSubmit: pushContentSubmit,
    pushModeContentSubmit: pushModeContentSubmit,
    getClassModeContentData: getClassModeContentData,
  };
};

const sanitizeClassContentIds = (values) => {
  let classContentIds = [];

  classContentIds = Object.keys(values);

  classContentIds.splice(classContentIds.indexOf(values.device_uuids), 1);

  return classContentIds;
};
