import { EyesUpModalContainer } from "@/app/app-components/providers/DeviceActions/EyesUp/containers/EyesUpModalContainer";
import { createContext, useEffect, useState } from "react";
import { AddTagsModalContainer } from "./AddTags/containers/AddTagsModalContainer";
import { BluetoothModalContainer } from "./Bluetooth/containers/BluetoothModalContainer";
import { EnableLostModeModalContainer } from "./EnableLostMode/containers/EnableLostModeModalContainer";
import { LockWithPinModalContainer } from "./LockWithPin/containers/LockWithPinModalContainer";
import { MirrorDeviceModalContainer } from "./MirrorDevice/containers/MirrorDeviceModalContainer";
import { PushContentModalContainer } from "./PushContent/containers/PushContentModalContainer.jsx/PushContentModalContainer";
import { RemoveDevicesModalContainer } from "./RemoveDevices/containers/RemoveDevicesModalContainer";
import { RemoveTagsModalContainer } from "./RemoveTags/containers/RemoveTagsModalContainer";
import { SendMessageModalContainer } from "./SendMessage/containers/SendMessageModalContainer";
import { SingleAppModeModalContainer } from "./SingleAppMode/containers/SingleAppModeModalContainer";
import { logger } from "@/app/services/logging/Logger";

export const DeviceActionContext = createContext(null);

export const DeviceActionProvider = ({ children }) => {
  const [eyesUp_data, setEyesUp_data] = useState(null);
  const [sendMessage_data, setSendMessage_data] = useState(null);
  const [lockWithPin_data, setLockWithPin_data] = useState(null);
  const [pushContent_data, setPushContent_data] = useState(null);
  const [enableLostMode_data, setEnableLostMode_data] = useState(null);
  const [addTags_data, setAddTags_data] = useState(null);
  const [removeTags_data, setRemoveTags_data] = useState(null);
  const [removeDevices_data, setRemoveDevices_data] = useState(null);
  const [mirrorDevice_data, setMirrorDevice_data] = useState(null);
  const [bluetooth_data, setBluetooth_data] = useState(null);
  const [singleAppMode_data, setSingleAppMode_data] = useState(null);
  // const [vacationMode_data, setvacationMode_data] = useState(null);

  // This is not needed in main app. Issue only exists in Storybook because when changing stories, the wrapping
  useEffect(() => {
    return () => {};
  }, []);

  const deviceAction_eyesUp = ({ device, classId, eyesUpStatus, onEyesUpSuccess, onEyesUpFailure, onEyesUpCancel }) => {
    if (!device) {
      logger.warn("DeviceActionsProvider: No device(s) provided, cannot perform Eyes up command");
      return;
    }

    if (eyesUpStatus === undefined) {
      logger.warn("DeviceActionsProvider: No eyesUpStatus provided, cannot perform Eyes up command");
      return;
    }

    const onEyesUpFailureHandler = (data) => {
      setEyesUp_data(null);
      if (typeof onEyesUpFailure === "function") onEyesUpFailure(data);
    };

    const onEyesUpSuccessHandler = (data) => {
      setEyesUp_data(null);
      if (typeof onEyesUpSuccess === "function") onEyesUpSuccess(data);
    };

    const onEyesUpCancelHandler = () => {
      setEyesUp_data(null);
      if (typeof onEyesUpCancel === "function") onEyesUpCancel();
    };

    const eyesUp_dataObj = {
      device,
      classId,
      eyesUpStatus,
      onEyesUpSuccess: onEyesUpSuccessHandler,
      onEyesUpFailure: onEyesUpFailureHandler,
      onCancel: onEyesUpCancelHandler,
    };

    setEyesUp_data(eyesUp_dataObj);
  };

  const deviceAction_mirrorDevice = ({
    device_uuid,
    onMirrorDeviceSuccess,
    onMirrorDeviceFailure,
    onMirrorDeviceCancel,
  }) => {
    if (!device_uuid) {
      logger.warn("DeviceActionsProvider: No device(s) provided, cannot perform Mirror Device command");
      return;
    }

    const onMirrorDeviceFailureHandler = (data) => {
      setMirrorDevice_data(null);
      if (typeof onMirrorDeviceFailure === "function") onMirrorDeviceFailure(data);
    };

    const onMirrorDeviceSuccessHandler = (data) => {
      setMirrorDevice_data(null);
      if (typeof onMirrorDeviceSuccess === "function") onMirrorDeviceSuccess(data);
    };

    const onMirrorDeviceCancelHandler = () => {
      setMirrorDevice_data(null);
      if (typeof onMirrorDeviceCancel === "function") onMirrorDeviceCancel();
    };

    const mirrorDevice_dataObj = {
      device_uuid,
      onMirrorDeviceSuccess: onMirrorDeviceSuccessHandler,
      onMirrorDeviceFailure: onMirrorDeviceFailureHandler,
      onMirrorDeviceCancel: onMirrorDeviceCancelHandler,
    };

    setMirrorDevice_data(mirrorDevice_dataObj);
  };

  const deviceAction_sendMessage = ({
    device_uuids,
    onSendMessageSuccess,
    onSendMessageFailure,
    onSendMessageCancel,
  }) => {
    if (!device_uuids) {
      logger.warn("DeviceActionsProvider: No device_uuids provided, cannot perform Send message command");
      return;
    }

    const onSendMessageFailureHandler = (data) => {
      setSendMessage_data(null);
      if (typeof onSendMessageFailure === "function") onSendMessageFailure(data);
    };

    const onSendMessageSuccessHandler = (data) => {
      setSendMessage_data(null);
      if (typeof onSendMessageSuccess === "function") onSendMessageSuccess(data);
    };

    const onSendMessageCancelHandler = () => {
      setSendMessage_data(null);
      if (typeof onSendMessageCancel === "function") onSendMessageCancel();
    };

    const sendMessage_dataObj = {
      device_uuids,
      onSendMessageSuccess: onSendMessageSuccessHandler,
      onSendMessageFailure: onSendMessageFailureHandler,
      onCancel: onSendMessageCancelHandler,
    };

    setSendMessage_data(sendMessage_dataObj);
  };

  const deviceAction_pushContent = ({
    device_uuids,
    classId,
    sessionId,
    classModeId,
    onPushContentSuccess,
    onPushContentFailure,
    onPushContentCancel,
  }) => {
    if (!device_uuids) {
      logger.warn("DeviceActionsProvider: No device_uuids provided, cannot perform Push content command");
      return;
    }

    if (!classId) {
      logger.warn("DeviceActionsProvider: No classId provided, cannot perform Push content command");
      return;
    }

    const onPushContentFailureHandler = (data) => {
      setPushContent_data(null);
      if (typeof onPushContentFailure === "function") onPushContentFailure(data);
    };

    const onPushContentSuccessHandler = (data) => {
      setPushContent_data(null);
      if (typeof onPushContentSuccess === "function") onPushContentSuccess(data);
    };

    const onPushContentCancelHandler = () => {
      setPushContent_data(null);
      if (typeof onPushContentCancel === "function") onPushContentCancel();
    };

    const pushContent_dataObj = {
      device_uuids,
      classId,
      sessionId,
      classModeId,
      onPushContentSuccess: onPushContentSuccessHandler,
      onPushContentFailure: onPushContentFailureHandler,
      onCancel: onPushContentCancelHandler,
    };

    setPushContent_data(pushContent_dataObj);
  };

  const deviceAction_lockWithPin = ({ devices, onLockWithPinSuccess, onLockWithPinFailure, onLockWithPinCancel }) => {
    if (!devices) {
      logger.warn("DeviceActionsProvider: No devices provided, cannot perform Lock With Pin command");
      return;
    }

    const onLockWithPinFailureHandler = (data) => {
      setLockWithPin_data(null);
      if (typeof onLockWithPinFailure === "function") onLockWithPinFailure(data);
    };

    const onLockWithPinSuccessHandler = (data) => {
      setLockWithPin_data(null);
      if (typeof onLockWithPinSuccess === "function") onLockWithPinSuccess(data);
    };

    const onLockWithPinCancelHandler = () => {
      setLockWithPin_data(null);
      if (typeof onLockWithPinCancel === "function") onLockWithPinCancel();
    };

    const lockWithPin_dataObj = {
      devices,
      onLockWithPinSuccess: onLockWithPinSuccessHandler,
      onLockWithPinFailure: onLockWithPinFailureHandler,
      onCancel: onLockWithPinCancelHandler,
    };

    setLockWithPin_data(lockWithPin_dataObj);
  };

  const deviceAction_enableLostMode = ({
    devices,
    onEnableLostModeSuccess,
    onEnableLostModeFailure,
    onEnableLostModeCancel,
  }) => {
    if (!devices) {
      logger.warn("DeviceActionsProvider: No devices provided, cannot perform Enable Lost Mode command");
      return;
    }

    const onEnableLostModeFailureHandler = (data) => {
      setEnableLostMode_data(null);
      if (typeof onEnableLostModeFailure === "function") onEnableLostModeFailure(data);
    };

    const onEnableLostModeSuccessHandler = (data) => {
      setEnableLostMode_data(null);
      if (typeof onEnableLostModeSuccess === "function") onEnableLostModeSuccess(data);
    };

    const onEnableLostModeCancelHandler = () => {
      setEnableLostMode_data(null);
      if (typeof onEnableLostModeCancel === "function") onEnableLostModeCancel();
    };

    const enableLostMode_dataObj = {
      devices,
      onEnableLostModeSuccess: onEnableLostModeSuccessHandler,
      onEnableLostModeFailure: onEnableLostModeFailureHandler,
      onCancel: onEnableLostModeCancelHandler,
    };

    setEnableLostMode_data(enableLostMode_dataObj);
  };

  const deviceAction_addTags = ({ devices, onAddTagsSuccess, onAddtagsFailure, onAddTagsCancel }) => {
    if (!devices) {
      logger.warn("DeviceActionsProvider: No devices provided, cannot perform Add tags command");
      return;
    }

    const onAddtagsFailureHandler = (data) => {
      setAddTags_data(null);
      if (typeof onAddtagsFailure === "function") onAddtagsFailure(data);
    };

    const onAddTagsSuccessHandler = (data) => {
      setAddTags_data(null);
      if (typeof onAddTagsSuccess === "function") onAddTagsSuccess(data);
    };

    const onAddTagsCancelHandler = () => {
      setAddTags_data(null);
      if (typeof onAddTagsCancel === "function") onAddTagsCancel();
    };

    const addTags_dataObj = {
      devices,
      onAddTagsSuccess: onAddTagsSuccessHandler,
      onAddtagsFailure: onAddtagsFailureHandler,
      onAddTagsCancel: onAddTagsCancelHandler,
    };

    setAddTags_data(addTags_dataObj);
  };

  const deviceAction_removeTags = ({ devices, onRemoveTagsSuccess, onRemoveTagsFailure, onRemoveTagsCancel }) => {
    if (!devices) {
      logger.warn("DeviceActionsProvider: No devices provided, cannot perform Remove tags command");
      return;
    }

    const onRemoveTagsFailureHandler = (data) => {
      setRemoveTags_data(null);
      if (typeof onRemoveTagsFailure === "function") onRemoveTagsFailure(data);
    };

    const onRemoveTagsSuccessHandler = (data) => {
      setRemoveTags_data(null);
      if (typeof onRemoveTagsSuccess === "function") onRemoveTagsSuccess(data);
    };

    const onRemoveTagsCancelHandler = () => {
      setRemoveTags_data(null);
      if (typeof onRemoveTagsCancel === "function") onRemoveTagsCancel();
    };

    const removeTags_dataObj = {
      devices,
      onRemoveTagsSuccess: onRemoveTagsSuccessHandler,
      onRemoveTagsFailure: onRemoveTagsFailureHandler,
      onRemoveTagsCancel: onRemoveTagsCancelHandler,
    };

    setRemoveTags_data(removeTags_dataObj);
  };

  const deviceAction_removeDevices = ({
    devices,
    onRemoveDevicesSuccess,
    onRemoveDevicesFailure,
    onRemoveDevicesCancel,
  }) => {
    if (!devices) {
      logger.warn("DeviceActionsProvider: No devices provided, cannot perform Remove devices command");
      return;
    }

    const onRemoveDevicesFailureHandler = (data) => {
      setRemoveDevices_data(null);
      if (typeof onRemoveDevicesFailure === "function") onRemoveDevicesFailure(data);
    };

    const onRemoveDevicesSuccessHandler = (data) => {
      setRemoveDevices_data(null);
      if (typeof onRemoveDevicesSuccess === "function") onRemoveDevicesSuccess(data);
    };

    const onRemoveDevicesCancelHandler = () => {
      setRemoveDevices_data(null);
      if (typeof onRemoveDevicesCancel === "function") onRemoveDevicesCancel();
    };

    const removeDevices_dataObj = {
      devices,
      onRemoveDevicesSuccess: onRemoveDevicesSuccessHandler,
      onRemoveDevicesFailure: onRemoveDevicesFailureHandler,
      onRemoveDevicesCancel: onRemoveDevicesCancelHandler,
    };

    setRemoveDevices_data(removeDevices_dataObj);
  };

  const deviceAction_bluetooth = ({ device_uuids, onBluetoothSuccess, onBluetoothFailure, onBluetoothCancel }) => {
    if (!device_uuids) {
      logger.warn("DeviceActionsProvider: No device(s) provided, cannot perform Mirror Device command");
      return;
    }

    const onBluetoothFailureHandler = (data) => {
      setBluetooth_data(null);
      if (typeof onBluetoothFailure === "function") onBluetoothFailure(data);
    };

    const onBluetoothSuccessHandler = (data) => {
      setBluetooth_data(null);
      if (typeof onBluetoothSuccess === "function") onBluetoothSuccess(data);
    };

    const onBluetoothCancelHandler = () => {
      setBluetooth_data(null);
      if (typeof onBluetoothCancel === "function") onBluetoothCancel();
    };

    const bluetooth_dataObj = {
      device_uuids,
      onBluetoothSuccess: onBluetoothSuccessHandler,
      onBluetoothFailure: onBluetoothFailureHandler,
      onBluetoothCancel: onBluetoothCancelHandler,
    };

    setBluetooth_data(bluetooth_dataObj);
  };

  const deviceAction_singleAppMode = ({
    device_uuid,
    onSingleAppModeSuccess,
    onSingleAppModeFailure,
    onSingleAppModeCancel,
  }) => {
    if (!device_uuid) {
      logger.warn("DeviceActionsProvider: No device(s) provided, cannot perform Single App Mode command");
      return;
    }
    const onSingleAppModeFailureHandler = (data) => {
      setSingleAppMode_data(null);
      if (typeof onSingleAppModeFailure === "function") onSingleAppModeFailure(data);
    };
    const onSingleAppModeSuccessHandler = (data) => {
      setSingleAppMode_data(null);
      if (typeof onSingleAppModeSuccess === "function") onSingleAppModeSuccess(data);
    };
    const onSingleAppModeCancelHandler = () => {
      setSingleAppMode_data(null);
      if (typeof onSingleAppModeCancel === "function") onSingleAppModeCancel();
    };
    const singleAppMode_dataObj = {
      device_uuid,
      onSingleAppModeSuccess: onSingleAppModeSuccessHandler,
      onSingleAppModeFailure: onSingleAppModeFailureHandler,
      onSingleAppModeCancel: onSingleAppModeCancelHandler,
    };
    setSingleAppMode_data(singleAppMode_dataObj);
  };

  return (
    <DeviceActionContext.Provider
      value={{
        deviceAction_eyesUp,
        deviceAction_sendMessage,
        deviceAction_pushContent,
        deviceAction_lockWithPin,
        deviceAction_enableLostMode,
        deviceAction_addTags,
        deviceAction_removeTags,
        deviceAction_removeDevices,
        deviceAction_mirrorDevice,
        deviceAction_bluetooth,
        deviceAction_singleAppMode,
      }}
    >
      {children}

      {eyesUp_data && <EyesUpModalContainer {...eyesUp_data} />}

      {sendMessage_data && <SendMessageModalContainer {...sendMessage_data} />}

      {pushContent_data && <PushContentModalContainer {...pushContent_data} />}

      {lockWithPin_data && <LockWithPinModalContainer {...lockWithPin_data} />}

      {enableLostMode_data && <EnableLostModeModalContainer {...enableLostMode_data} />}

      {addTags_data && <AddTagsModalContainer {...addTags_data} />}

      {removeTags_data && <RemoveTagsModalContainer {...removeTags_data} />}

      {removeDevices_data && <RemoveDevicesModalContainer {...removeDevices_data} />}

      {mirrorDevice_data && <MirrorDeviceModalContainer {...mirrorDevice_data} />}

      {bluetooth_data && <BluetoothModalContainer {...bluetooth_data} />}

      {singleAppMode_data && <SingleAppModeModalContainer {...singleAppMode_data} />}
    </DeviceActionContext.Provider>
  );
};

export default DeviceActionProvider;
