import { currentUserVar } from "@/cache";
import MGContent from "@/app/app-components/MGContent/MGContent";
import { useReactiveVar } from "@apollo/client";
import { backLinkVar, pageTitleVar, backTitleVar } from "@/cache";
import "./style.css";
import { WelcomeSplash } from "./components/WelcomeSplash/WelcomeSplash";
import { HomePageClassList } from "./components/HomePageClassList/HomePageClassList";
import { useTranslation } from "react-i18next";
import useUpcomingClasses from "./components/HomePageClassList/useUpcomingClasses";
import useRecentlyEndedClasses from "./components/HomePageClassList/useRecentlyEndedClasses";
import { useStartClass } from "@/app/app-components/providers/ClassActions/useStartClass";
import { useNavigate } from "react-router";

import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { useEffect } from "react";
import { MGButton, MGLinkButton } from "@/components/buttons";
// import useClassList from "./components/HomePageClassList/useClassList";
import { MGCardContent, MGCardFooter } from "@/components/card";
import MGAvatar from "@/components/avatar/MGAvatar";
import { MGHeading3 } from "@/components/typography";

import MGSurface from "@/app/app-components/MGSurface/MGSurface";

export const HomePage = () => {
  const currentUser = useReactiveVar(currentUserVar);

  useEffect(() => {
    backTitleVar("");
    pageTitleVar("");
    backLinkVar("");
  }, []);

  const navigate = useNavigate();
  const [notification] = useNotification();

  const { t } = useTranslation();

  const upcomingClassesProps = useUpcomingClasses();
  // const inProgressClassProps = useClassList(true, false);

  const recentlyEndedClassesProps = useRecentlyEndedClasses();

  let nextClass = null;

  if (upcomingClassesProps.data && upcomingClassesProps.data[0]) nextClass = upcomingClassesProps.data[0];

  const [startClassModal] = useStartClass();

  const showStartClassModal = ({ id, screenShareMode, duration, className }) => {
    startClassModal({
      classId: id,
      screenShareMode,
      duration,
      className,
      onSuccess: () => {
        notification.success({
          message: t("Class started successfully"),
        });
        navigate(`/teach/class/${id}`);
      },
      onFailure: () => {
        notification.error({ message: t("Could not start class, please try again later or contact support") });
      },
    });
  };

  return (
    <MGContent>
      {nextClass ? (
        <div className="--mg-flex-box upcoming-recent">
          <>
            <div className="flex-1">
              <MGSurface>
                <MGCardContent>
                  <div style={{ textAlign: "center", marginTop: "20px" }}>
                    <MGAvatar color={nextClass?.color} letter={nextClass?.className}></MGAvatar>
                  </div>
                  <br />
                  <div style={{ textAlign: "center" }}>
                    <div className="next-class">{t("Your next class")}</div>
                    <br />

                    <MGHeading3>{nextClass?.className}</MGHeading3>

                    <div>{nextClass?.schedule && <p className="--mg-grey-text">{nextClass?.schedule}</p>}</div>
                  </div>
                  <br />
                </MGCardContent>
                <MGCardFooter>
                  <div style={{ textAlign: "center", marginBottom: "var(--mg-spacing-lg)" }}>
                    <MGLinkButton variant={"tertiary"} to={`/plan/class/${nextClass?.id}`}>
                      {t("Plan Class")}
                    </MGLinkButton>

                    <MGButton
                      variant="secondary"
                      onClick={() => {
                        showStartClassModal({
                          id: nextClass.id,
                          screenShareMode: nextClass.screen_share_enabled,
                          duration: nextClass.duration,
                          className: nextClass.className,
                        });
                      }}
                    >
                      {t("Start Class")}
                    </MGButton>
                  </div>
                </MGCardFooter>
              </MGSurface>
            </div>

            <div className="flex-1">
              <WelcomeSplash
                // currentClass={
                //   inProgressClassProps.data && inProgressClassProps.data[0] ? inProgressClassProps.data[0] : null
                // }
                nextClass={nextClass}
                showStartClassModal={showStartClassModal}
                currentUser={currentUser}
              />
            </div>
          </>
        </div>
      ) : (
        <WelcomeSplash
          // currentClass={inProgressClassProps.data && inProgressClassProps.data[0] ? inProgressClassProps.data[0] : null}
          nextClass={nextClass}
          showStartClassModal={showStartClassModal}
          currentUser={currentUser}
        />
      )}

      <br />
      <div className="--mg-flex-box upcoming-recent">
        <div className="flex-1">
          <HomePageClassList
            heading={t("Today's upcoming classes")}
            classDataProps={upcomingClassesProps}
            redirectTo={`/plan/class/$classId`}
            emptyListMessage={t("You do not have any upcoming scheduled classes")}
            errorMessage={t("Could not load upcoming classes, please try again later or contact support")}
            actionButton={(classObj) => {
              return (
                <>
                  <MGButton
                    variant="secondary"
                    onClick={(evt) => {
                      // evt.stopPropagation();
                      evt.preventDefault();
                      showStartClassModal({
                        id: classObj.id,
                        screenShareMode: classObj.screen_share_enabled,
                        duration: classObj.duration,
                        className: classObj.className,
                      });
                    }}
                  >
                    {t("Start Class")}
                  </MGButton>
                </>
              );
            }}
          />
        </div>
        <div className="flex-1">
          <HomePageClassList
            heading={t("Recently Ended")}
            classDataProps={recentlyEndedClassesProps}
            redirectTo={`/review/class/$classId/`}
            emptyListMessage={t("You do not have any recently ended classes")}
            errorMessage={t("Could not load recently ended classes, please try again later or contact support")}
            actionButton={(classObj) => {
              return (
                <>
                  <MGLinkButton to={`/review/class/${classObj.id}/session/${classObj.sessionId}`} variant="tertiary">
                    {t("Review Session")}
                  </MGLinkButton>
                </>
              );
            }}
          />
        </div>
      </div>
    </MGContent>
  );
};
