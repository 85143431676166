import { MGModal } from "@/components/modals";
import MGConfirmationModal from "@/app/app-components/confirmation-modal/MGConfirmationModal";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

export const RemoveDevicesModal = ({ isShowing, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const onCloseHandler = () => {
    if (typeof onClose === "function") onClose();
  };

  const onSuccessHandler = (data) => {
    if (typeof onSuccess === "function") onSuccess(data);
  };

  return (
    <>
      <MGModal isShowing={isShowing}>
        <MGConfirmationModal
          title={t("Remove Device(s)")}
          message={t("Are you sure you want to delete the selected device(s)?")}
          positive={t("Yes")}
          // positiveIsLoading={removeDeviceLoading}
          negative={t("No")}
          onClose={onCloseHandler}
          onSuccess={onSuccessHandler}
        />
      </MGModal>
    </>
  );
};

RemoveDevicesModal.propTypes = {
  isShowing: PropTypes.bool,
  // removeDeviceLoading: PropTypes.bool,
  onClose: PropTypes.func,
  onSuccess: PropTypes.func,
};

export default RemoveDevicesModal;
