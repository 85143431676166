import { DEVICE_ACTION_EYES_UP_MUTATION as MUTATION_DEVICE_ACTION_EYES_UP } from "@/app/gql/devices/device_actions_mutations";
import screenShareSocket from "@/app/services/socket/screen-share-socket";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useEyesUpMutation = ({ device, classId }) => {
  const [eyesUpMutation] = useMGMutation(MUTATION_DEVICE_ACTION_EYES_UP);
  const [loading, setLoading] = useState(false);

  const [notification] = useNotification();

  const { t } = useTranslation();

  const sendSocketMessage = (device_signature, status) => {
    screenShareSocket.socket.emit(screenShareSocket.SCREEN_SHARE_SEND, {
      from: screenShareSocket.socket.id ?? "notset",
      to: device_signature,
      class: classId,
      payload: {
        action: "blockscreen",
        message: undefined,
        status: status ? "OFF" : "ON",
        module: "eyesUp",
        data: "data",
        from: screenShareSocket.socket.id ?? "notset",
        to: device_signature,
      },
    });
  };

  const eyesUpSubmit = async (status) => {
    setLoading(true);

    let eyesUpPayload = {
      device_uuids: [],
      eyesUpStatus: status,
    };

    eyesUpPayload.device_uuids = device.map((d) => d.device_uuid);
    const response = await eyesUpMutation({
      variables: {
        input: eyesUpPayload,
      },
      onError: (data) => {
        setLoading(false);
        throw data;
      },
    });

    if (response && response.data.deviceActionEyesUp) {
      let { data, errors } = response.data.deviceActionEyesUp;
      if (errors) {
        notification.error({
          message:
            eyesUpPayload.eyesUpStatus == "ON"
              ? t("Failed to put device into eyes up")
              : t("Failed to disable eyes up"),
        });
      }

      if (data.handled) {
        if (Array.isArray(device)) {
          device.map((d) => {
            sendSocketMessage(d.device_signature, status);
          });
        } else {
          sendSocketMessage(device.device_signature, status);
        }

        notification.success({
          message: eyesUpPayload.eyesUpStatus == "ON" ? t("Eyes up command sent") : t("Eyes down command sent"),
          tiny: true,
        });
      }
    }
    setLoading(false);
    return response;
  };

  return {
    eyesUpLoading: loading,
    eyesUpSubmit: eyesUpSubmit,
    // eyesUpMode,
    // setEyesUpMode,
  };
};
