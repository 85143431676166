import { MUTATION_DEVICE_ACTION_BLUETOOTH } from "@/app/gql/devices/device_actions_mutations";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { useState } from "react";
import { useTranslation } from "react-i18next";

export const useBluetoothMutation = ({ device_uuids }) => {
  const [bluetoothMutation] = useMGMutation(MUTATION_DEVICE_ACTION_BLUETOOTH);
  const [loading, setLoading] = useState(false);

  const [notification] = useNotification();

  const { t } = useTranslation();

  const bluetoothSubmit = async (values) => {
    setLoading(true);

    let bluetoothPayload = {
      device_uuids: Array.isArray(device_uuids) ? device_uuids.map((d) => d) : device_uuids,
      enabled: values.bluetooth_option,
    };

    const response = await bluetoothMutation({
      variables: {
        input: bluetoothPayload,
      },
      onError: (data) => {
        setLoading(false);
        throw data;
      },
    });

    if (response && response.data.deviceActionBluetooth) {
      let { data, errors } = response.data.deviceActionBluetooth;
      if (errors) {
        notification.error({
          message: t("Failed to change bluetooth status"),
        });
      }

      if (data.handled) {
        notification.success({ message: t("Successfully changed bluetooth status"), tiny: true });
      }
    }
    setLoading(false);
    return response;
  };

  return {
    bluetoothLoading: loading,
    bluetoothSubmit: bluetoothSubmit,
  };
};
