import { Form, Formik } from "formik";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGHeading3, MGHelperText, MGLabel } from "@/components/typography";
import { MGButton } from "@/components/buttons";
import * as yup from "yup";
import { MGTextboxField } from "@/components/inputs";
import PropTypes from "prop-types";
import UserUtils from "@/app/utils/users/user_utils";
import { useTranslation } from "react-i18next";
import { logger } from "@/app/services/logging/Logger";

const EnableLostModeModal = (props) => {
  const { isShowing, initialValues, onClose, onSubmit } = props;

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    lostMessage: yup.string(),
    lostNumber: yup.string(),
    lostFootnote: yup.string(),
  });

  const onSubmitHandler = (values, formik) => {
    if (typeof onSubmit === "function") {
      onSubmit(values, formik);
    } else {
      logger.debug("EnableLostMode: no 'onSubmit' prop provided to Modal, cannot send message.");
    }
  };

  return (
    <MGModal isShowing={isShowing}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitHandler}>
        {({ dirty, isSubmitting, isValid, submitForm }) => {
          return (
            <>
              <MGModalHeader>
                <MGHeading3>{t("Enable Lost Mode")}</MGHeading3>
              </MGModalHeader>
              <MGModalBody>
                <Form>
                  <MGLabel htmlFor="lostMessage">{t("Message")}</MGLabel>
                  <MGTextboxField
                    name="lostMessage"
                    placeholder={`Please return to ${UserUtils.getCurrentSchoolUser()?.school?.name}`}
                  />
                  <MGHelperText>{t("This message will be displayed on the device")}</MGHelperText>
                  <br />
                  <MGLabel htmlFor="lostNumber">{t("Contact number")}</MGLabel>
                  <MGTextboxField name="lostNumber" placeholder="012 345 6789" />
                  <MGHelperText>{t("Enter a contact number to display below the message")}</MGHelperText>
                  <br />
                  <MGLabel htmlFor="lostFootnote">{t("Footnote")}</MGLabel>
                  <MGTextboxField name="lostFootnote" placeholder="" />
                  <MGHelperText>{t("Enter any additional information which will appear as a footnote")}</MGHelperText>
                </Form>
              </MGModalBody>
              <MGModalFooter>
                <MGButton variant="tertiary" onClick={onClose}>
                  {t("Cancel")}
                </MGButton>
                <MGButton variant="primary" isLoading={isSubmitting} disabled={!dirty || !isValid} onClick={submitForm}>
                  {t("Enable")}
                </MGButton>
              </MGModalFooter>
            </>
          );
        }}
      </Formik>
    </MGModal>
  );
};

EnableLostModeModal.propTypes = {
  isShowing: PropTypes.bool,
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

EnableLostModeModal.defaultProps = {};

export default EnableLostModeModal;
