import { useDeviceActionEnableLostMode } from "../../hooks/useDeviceActionEnableLostMode";
import EnableLostModeModal from "../components/EnableLostModeModal";
import PropTypes from "prop-types";

export const EnableLostModeModalContainer = ({
  devices,
  onEnableLostModeSuccess,
  onEnableLostModeFailure,
  onCancel,
}) => {
  const [deviceActionEnableLostMode] = useDeviceActionEnableLostMode();

  const onSubmitHandler = async (values) => {
    try {
      const { data } = await deviceActionEnableLostMode(
        devices,
        values.lostmessage,
        values.lostnumber,
        values.lostfootnote
      );
      if (typeof onEnableLostModeSuccess === "function") onEnableLostModeSuccess(data);
    } catch (e) {
      onSendMessageFailureHandler(e);
    }
  };

  const onSendMessageFailureHandler = (err) => {
    if (typeof onEnableLostModeFailure === "function") onEnableLostModeFailure(err);
  };

  const onCancelHandler = () => {
    if (typeof onCancel === "function") onCancel();
  };

  return (
    <>
      <EnableLostModeModal
        isShowing={devices && devices.length > 0}
        initialValues={{
          lostMessage: "",
          lostNumber: "",
          lostFootnote: "",
        }}
        onClose={onCancelHandler}
        onSubmit={onSubmitHandler}
      />
    </>
  );
};

EnableLostModeModalContainer.propTypes = {
  devices: PropTypes.array,
  onEnableLostModeSuccess: PropTypes.func,
  onEnableLostModeFailure: PropTypes.func,
  onCancel: PropTypes.func,
};
