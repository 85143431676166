import { gql } from "@apollo/client";

export const QUERY_CLASS_MODES = gql`
  query classModes($limit: Int, $page: Int) {
    classModes(limit: $limit, page: $page) {
      __typename
      ... on ClassModeConnection {
        edges {
          node {
            id
            name
            description
            created_at
            updated_at
            deleted_at
            allow_raise_hand
            # list, data comes from loader (currently inefficient)
            classes {
              id
              name
            }
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
      }
    }
  }
`;

export const QUERY_CLASS_MODE = gql`
  query classMode($classModeId: ID!) {
    classMode(classModeId: $classModeId) {
      __typename
      ... on ClassMode {
        id
        name
        description
        created_at
        updated_at
        deleted_at
        allow_raise_hand
        # single record, data comes from include
        classes {
          id
          name
        }
      }
      #  not found, not implemented yet, 400 instead of 404
      ... on RecordNotFoundProblem {
        message
      }
      ... on NotQuiteSureProblem {
        message
      }
    }
  }
`;

export const QUERY_CLASS_MODE_CONTENTS = gql`
  query classModeContents($classModeId: ID!, $limit: Int, $page: Int, $q: String) {
    classModeContents(classModeId: $classModeId, page: $page, limit: $limit, q: $q) {
      __typename
      ... on ClassModeContentConnection {
        edges {
          node {
            id
            name
            description
            object_type
            object_content
            meta_url
            meta_data
            created_at
            updated_at
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const QUERY_CLASS_MODE_APPLICATIONS = gql`
  query classModeApplications($classModeId: ID!, $limit: Int, $page: Int) {
    classModeApplications(classModeId: $classModeId, limit: $limit, page: $page) {
      __typename
      ... on ClassModeApplicationConnection {
        edges {
          node {
            id
            name
            description
            object_type
            object_content
            object_details {
              id
              name
              description
              bundle_name
              created_at
              updated_at
              bundle_name
              vendor_name
              os_type_id
              external_id
              third_party_browser
              text_while_driving
              is_inappropriate
              is_free
              is_factory
              device_families
              supported_devices
              required_capabilities
              minimum_os_version
              is_32_bit_only
              genres
              icon
            }
            meta_data
            created_at
            updated_at
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const QUERY_CLASS_MODE_WEB_FILTER_ALLOWED_URLS = gql`
  query classModeWebfilterAllowedURLs($classModeId: ID!, $limit: Int, $page: Int) {
    classModeWebfilterAllowedURLs(classModeId: $classModeId, limit: $limit, page: $page) {
      __typename
      ... on ClassModeContentConnection {
        edges {
          node {
            id
            name
            description
            object_type
            object_content
            meta_data
            created_at
            updated_at
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;
