import { Form, Formik } from "formik";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGTextAreaField } from "@/components/inputs/MGTextArea";
import { MGHeading3, MGLabel } from "@/components/typography";
import { MGButton } from "@/components/buttons";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { logger } from "@/app/services/logging/Logger";

const SendMessageModal = (props) => {
  const { isShowing, initialValues, onClose, onSubmit } = props;

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    message: yup.string().required(t("Required")),
  });

  const onSubmitHandler = (values, formik) => {
    if (typeof onSubmit === "function") {
      onSubmit(values, formik);
    } else {
      logger.debug("SendMessageModal: no 'onSubmit' prop provided to Modal, cannot send message.");
    }
  };

  return (
    <MGModal isShowing={isShowing}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitHandler}>
        {(props) => {
          const { dirty, isSubmitting, isValid, submitForm } = props;
          return (
            <>
              <MGModalHeader>
                <MGHeading3>{t("Send Message")}</MGHeading3>
              </MGModalHeader>
              <MGModalBody>
                <Form>
                  <MGLabel htmlFor="message">{t("Message text")}</MGLabel>
                  <MGTextAreaField name="message" placeholder={t("Your text here")}></MGTextAreaField>
                </Form>
              </MGModalBody>
              <MGModalFooter>
                <MGButton variant="tertiary" onClick={onClose}>
                  {t("Cancel")}
                </MGButton>
                <MGButton variant="primary" isLoading={isSubmitting} disabled={!dirty || !isValid} onClick={submitForm}>
                  {t("Send")}
                </MGButton>
              </MGModalFooter>
            </>
          );
        }}
      </Formik>
    </MGModal>
  );
};

export default SendMessageModal;
