import RemoveDevicesModal from "../components/RemoveDevicesModal";
import PropTypes from "prop-types";
import { useDeviceActionRemoveDevices } from "../../hooks/useDeviceActionRemoveDevice";

export const RemoveDevicesModalContainer = ({
  devices,
  onRemoveDevicesSuccess,
  onRemoveDevicesFailure,
  onRemoveDevicesCancel,
}) => {
  const [deviceActionRemoveDevices] = useDeviceActionRemoveDevices();

  const onCancelHandler = () => {
    if (typeof onRemoveDevicesCancel === "function") onRemoveDevicesCancel();
  };

  const onSubmitHandler = async () => {
    try {
      const { data } = await deviceActionRemoveDevices(devices);
      if (typeof onRemoveDevicesSuccess === "function") onRemoveDevicesSuccess(data);
    } catch (e) {
      onSubmitFailureHandler(e);
    }
  };

  const onSubmitFailureHandler = (err) => {
    if (typeof onRemoveDevicesFailure === "function") onRemoveDevicesFailure(err);
  };

  return (
    <>
      <RemoveDevicesModal
        isShowing={devices && devices.length > 0}
        // removeDevicesLoading={removeDeviceLoading}
        onClose={onCancelHandler}
        onSuccess={onSubmitHandler}
      />
    </>
  );
};

RemoveDevicesModalContainer.propTypes = {
  devices: PropTypes.any,
  onRemoveDevicesFailure: PropTypes.func,
  onRemoveDevicesSuccess: PropTypes.func,
  onRemoveDevicesCancel: PropTypes.func,
};
