import { gql } from "@apollo/client";

export const DeviceFragments = {
  base: gql`
    fragment DeviceBaseParts on Device {
      device_uuid
      device_signature
      device_name
      device_name_actual
      model
      product_name
      jailbroken
      imei
      ip_local
      ip_public
      mac_wifi
      mac_bluetooth
      os_type_id
      os_version
      os_platform
      serial
      last_seen
      is_chrome_managed
      created_at
      updated_at
      school_id
      client_version
      manufacturer
      push_token
      chromebook_android_token
      is_enrolled
      is_scep
      has_wallpaper
      is_placeholder
      last_acknowledge
      last_kick
      deleted_at
      cellular_technology
      is_supervised
      is_device_locator_service_enabled
      phone_number
      is_activation_lock_enabled
      is_do_not_disturb_in_effect
      is_roaming
      personal_hotspot_enabled
      hardware_encryption_caps
      passcode_present
      passcode_compliant
      passcode_compliant_with_profiles
      is_dep
      is_mdm_enroll
      activation_lock_bypass_code
      has_mg_app
      has_mg_webfilter
      is_lostmode
      is_app_locked {
        id
        name
        description
        bundle_name
        icon
      }
      max_resident_users
      google_id
      need_mdm_refresh
      mdm_refresh_in_progress
      is_parent_block_screen
      is_mirrored
      user_is_in_device_group
      mg_build_number
    }
  `,
  device_statistics: gql`
    fragment DeviceStatisticsDeviceInfoBaseParts on Device_Statistics_Device_Info {
      availableDeviceCapacity
      awaitingConfiguration
      batteryLevel
      bluetoothMAC
      buildVersion
      cellularTechnology
      dataRoamingEnabled
      deviceCapacity
      deviceName
      imei
      isCloudBackupEnabled
      isDeviceLocatorServiceEnabled
      isDoNotDisturbInEffect
      isMDMLostModeEnabled
      isRoaming
      isSupervised
      model
      modelName
      modemFirmwareVersion
      osVersion
      personalHotspotEnabled
      productName
      serialNumber
      subscriberMCC
      subscriberMNC
      udid
      voiceRoamingEnabled
      wiFiMAC
    }
    fragment DeviceStatisticsBaseParts on Device_Statistics {
      id
      device_uuid
      os_platform
      os_version
      manufacturer
      model
      product_name
      serial_number
      country_code
      timezone
      locale
      battery
      client_version
      cell_carrier
      bluetooth_mac
      ip_address
      wifi_mac
      wifi_ssid
      wifi_bssid
      storage_internal_size
      storage_internal_available
      storage_external_size
      storage_external_available
      created_at
      updated_at
      device_name
      source
      device_info {
        ...DeviceStatisticsDeviceInfoBaseParts
      }
      stats_push_registered
      status_push_enabled
      status_push_alerts
      status_push_badges
      status_push_sounds
      ip_no
      status_jail_broken
      status_removed_managed_account
      status_usage_access
      status_allow_display_over
    }
  `,
  computed: gql`
    fragment DeviceComputedParts on Device_Computed {
      isChromeManaged
      isMDM
      isAndroid
      isIOS
      isChrome
      isOSX
      isWindows
      osTypeId
      hasChromeEyesUpApp
      isEnrolled
      isLostMode
      areDetailsAvailable
      osVersionNumber
      lastSeenDate
      isSupervised
      name
      display_name
      canPerformAction
      hideSSID
      hideLocation
    }
  `,
  device_configuration: gql`
    fragment DeviceConfigurationBaseParts on Device_Configuration {
      device_uuid
      device_group_id
      unlock_pin
      sync_update_interval
      update_interval_battery_ok
      update_interval_battery_low
      sim_swap_policy
      disable_message_tracking
      disable_location_tracking
      created_at
      updated_at
      is_school_owned_device
      is_provisioned
      is_disabled
      os_version_update
      os_version_check_at
      is_classroom
      bluetooth_enabled
      ignore_sam
      sync_chrome_apps
      vacation_mode
    }
  `,
  active_profile: gql`
    fragment DeviceActiveProfileParts on Device_DeviceActiveProfile {
      device_uuid
      profile_uuid
      status
      deleted_at
      bump_revision
      ext_uuid
      name
      user_id
      profile_type
      baseline_profile_uuid
      is_block
      no_tracking
      display_colour
    }
  `,
  location: gql`
    fragment DeviceLocationBaseParts on Device_Location {
      id
      device_uuid
      latitude
      longitude
      created_at
      updated_at
      logged_timestamp
      bearing
      source_id
      accuracy
    }
  `,
};

export const DeviceInstalledApplicationsFragments = {
  installed_applications: gql`
    fragment DeviceInstalledApplicationBaseParts on DeviceInstalledApplication {
      id
      device_uuid
      device_signature
      application_id
      created_at
      updated_at
      blacklisted
      deleted_at
      school_id
      short_version
      version
      last_version_updated_at
      has_update
      adhoc_code_sign
      beta_app
      device_based_vpp
      app_store_vendable
      version_external_id
      my_catalogue
      installed
      candidate_install
      candidate_uninstall
      install_failed
      uninstall_failed
      last_failure_reason
      last_failure_response
      last_status_reason
      last_status_response
      vpp_licenses
      vpp_token
      last_license_association_type
      external
      plist
      mode
      scheduled
      send_count
      send_after
      last_mdm_command_status
      previous_mdm_command_status
      last_install_acknowledged_at
      name
      icon
      app_version_display
      app_version_external_id
      #block_settings // ! FIXME: failing when added to query
      bundle_name
      notification_settings
      os_type_id
      send_after_actual
    }
  `,
};
