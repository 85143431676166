import { Form, Formik } from "formik";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGHeading3, MGLabel } from "@/components/typography";
import { MGButton } from "@/components/buttons";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { MGDropdownField, MGDropdownItem } from "@/components/inputs";
import MGAvatar from "@/components/avatar/MGAvatar";
import { MGImage } from "@/components/image";
import { MGSpacing } from "@/components/spacing";
import { logger } from "@/app/services/logging/Logger";

const SingleAppModeModal = (props) => {
  const { isShowing, initialValues, onClose, onSubmit, apps } = props;

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    bundle_id: yup.string().required(t("Required")),
  });

  const onSubmitHandler = (values, formik) => {
    if (typeof onSubmit === "function") {
      onSubmit(values, formik);
    } else {
      logger.debug("SingleAppModeModal: no 'onSubmit' prop provided to Modal, cannot run command.");
    }
  };

  return (
    <MGModal isShowing={isShowing}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitHandler}>
        {(props) => {
          const { dirty, isSubmitting, isValid, submitForm } = props;
          return (
            <>
              <MGModalHeader>
                <MGHeading3>{t("Single App Mode")}</MGHeading3>
              </MGModalHeader>
              <MGModalBody>
                <Form>
                  <MGLabel>{t("Select App")}</MGLabel>
                  <MGDropdownField name="bundle_id" placeholder={t("Select app")}>
                    {apps &&
                      apps?.map((item, idx) => (
                        <MGDropdownItem key={idx} value={item.bundle_name} displayValue={item.name}>
                          {item.icon && <MGImage src={item.icon} width={26} fit="fill" />}
                          {!item.icon && <MGAvatar letter={item.name} isSmall={true} />}
                          <MGSpacing size={"md"} />
                          {item.name}
                        </MGDropdownItem>
                      ))}
                  </MGDropdownField>
                </Form>
              </MGModalBody>
              <MGModalFooter>
                <MGButton variant="tertiary" onClick={onClose}>
                  {t("Cancel")}
                </MGButton>
                <MGButton variant="primary" isLoading={isSubmitting} disabled={!dirty || !isValid} onClick={submitForm}>
                  {t("Confirm")}
                </MGButton>
              </MGModalFooter>
            </>
          );
        }}
      </Formik>
    </MGModal>
  );
};

export default SingleAppModeModal;
