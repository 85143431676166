import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { MUTATION_DEVICE_ACTION_LOCK_WITH_PIN } from "@/app/gql/devices/device_actions_mutations";
import { useTranslation } from "react-i18next";
import useMGMutation from "@/app/utils/gql/useMGMutation";

export const useDeviceActionLockWithPin = () => {
  const [notification] = useNotification();
  const { t } = useTranslation();

  const [deviceActionLockWithPinMutation] = useMGMutation(MUTATION_DEVICE_ACTION_LOCK_WITH_PIN);
  const deviceActionLockWithPin = async (devices, pin) => {
    const { data } = await deviceActionLockWithPinMutation({
      variables: {
        input: {
          device_uuids: devices.map((d) => d.device_uuid),
          pin: pin,
        },
      },
    });

    if (data && data.deviceActionLockWithPin) {
      let { data: response, errors } = data.deviceActionLockWithPin;
      if (errors) {
        notification.error({
          message:
            devices.length > 1
              ? t("Unable to send lock with pin command to {{%s}} devices", { "%s": devices.length })
              : t("Unable to send lock with pin command to device"),
        });
      }

      if (response.handled) {
        notification.success({
          message:
            response.handled.length > 1
              ? t("Lock with pin command sent to {{%s}} devices", { "%s": response.handled.length })
              : t("Lock with pin command sent to device"),
          tiny: true,
        });
      }
    }
  };

  return [deviceActionLockWithPin];
};
