import { MUTATION_DEVICE_ACTION_MASS_UNASSIGN_TAGS } from "@/app/gql/devices/device_actions_mutations";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { useTranslation } from "react-i18next";

export const useRemoveTagsMutation = () => {
  const [notification] = useNotification();

  const { t } = useTranslation();

  const [deviceActionRemoveTagsMutation] = useMGMutation(MUTATION_DEVICE_ACTION_MASS_UNASSIGN_TAGS);

  const deviceActionRemoveTags = async (devices, tags) => {
    const { data } = await deviceActionRemoveTagsMutation({
      variables: {
        input: {
          object_list: devices.map((d) => d.device_uuid),
          tag_list: tags.map((t) => t),
        },
      },
    });

    if (data && data.massAssignTagsDevice) {
      let { data: response, errors } = data.massAssignTagsDevice;
      if (errors) {
        notification.error({
          message:
            devices.length > 1
              ? t("Failed to remove tag(s) for {{%s}} devices", { "%s": devices.length })
              : t("Failed to remove tag(s) for device"),
        });
      }

      if (response.result) {
        notification.success({ message: t("Tag(s) removed for device(s)"), tiny: true });
      }
    }
  };

  return {
    deviceActionRemoveTags: deviceActionRemoveTags,
  };
};
