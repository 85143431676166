import { QUERY_DEVICE_SINGLE_APP_MODE_APPLICATIONS } from "@/app/gql/devices/devices_queries";
import useMGQuery from "@/app/utils/gql/useMGQuery";
import { useMemo } from "react";

import SingleAppModeModal from "../components/SingleAppModeModal";

import { useSingleAppModeMutation } from "./useSingleAppModeMutation";

export const SingleAppModeModalContainer = ({
  device_uuid,
  onSingleAppModeSuccess,
  onSingleAppModeFailure,
  onSingleAppModeCancel,
}) => {
  const { singleAppModeSubmit } = useSingleAppModeMutation({
    device_uuid,
  });

  const onSubmitHandler = async (values, formik) => {
    try {
      await singleAppModeSubmit(values, formik);
      if (typeof onSingleAppModeSuccess === "function") onSingleAppModeSuccess();
    } catch (e) {
      onSingleAppModeFailureHandler(e);
    }
  };

  const { data: singleAppModeAppsData } = useMGQuery(QUERY_DEVICE_SINGLE_APP_MODE_APPLICATIONS, {
    variables: {
      device_uuid: device_uuid,
      os_type_id: 4,
    },
  });

  //Memoize response from GQL
  const singleAppModeApps = useMemo(
    () =>
      singleAppModeAppsData?.deviceSingleModeApplications?.edges
        ? singleAppModeAppsData.deviceSingleModeApplications.edges.map(({ node }) => ({ ...node }))
        : [],
    [singleAppModeAppsData]
  );

  const onSingleAppModeFailureHandler = (err) => {
    if (typeof onSingleAppModeFailure === "function") onSingleAppModeFailure(err);
  };

  const onSingleAppModeCancelHandler = () => {
    if (typeof onSingleAppModeCancel === "function") onSingleAppModeCancel();
  };

  return (
    <>
      <SingleAppModeModal
        isShowing={device_uuid}
        initialValues={{}}
        onClose={onSingleAppModeCancelHandler}
        onSubmit={onSubmitHandler}
        apps={singleAppModeApps}
      />
    </>
  );
};
