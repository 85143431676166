import { gql } from "@apollo/client";

export const QUERY_TAGS = gql`
  query tags($q: String, $limit: Int) {
    tags(q: $q, limit: $limit) {
      __typename
      ... on TagConnection {
        edges {
          node {
            key
            value
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;
