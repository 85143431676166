import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { MUTATION_DEVICE_ACTION_DESTROY } from "@/app/gql/devices/device_actions_mutations";
import { useTranslation } from "react-i18next";
import useMGMutation from "@/app/utils/gql/useMGMutation";

export const useDeviceActionRemoveDevices = () => {
  const [notification] = useNotification();

  const { t } = useTranslation();

  const [deviceActionRemoveDevicesMutation] = useMGMutation(MUTATION_DEVICE_ACTION_DESTROY);
  const deviceActionRemoveDevices = async (devices) => {
    const { data } = await deviceActionRemoveDevicesMutation({
      variables: {
        input: {
          device_uuids: devices.map((d) => d.device_uuid),
        },
      },
    });

    if (data && data.deviceActionRemoveDevices) {
      let { data: response, errors } = data.deviceActionRemoveDevices;
      if (errors) {
        notification.error({
          message:
            devices.length > 1
              ? t("Unable to send remove device command to {{%s}} devices", { "%s": devices.length })
              : t("Unable to send remove device to device"),
        });
      }

      if (response.handled) {
        notification.success({
          message:
            response.handled.length > 1
              ? t("Remove device command sent to {{%s}} devices", { "%s": response.handled.length })
              : t("Remove device command sent to device"),
          tiny: true,
        });
      }
    }
  };

  return [deviceActionRemoveDevices];
};
