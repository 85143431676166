import { MUTATION_DEVICE_ACTION_SEND_MESSAGE } from "@/app/gql/devices/device_actions_mutations";
import useMGMutation from "@/app/utils/gql/useMGMutation";

export const useSendMessageMutation = ({ device_uuids }) => {
  const [sendMessageMutation] = useMGMutation(MUTATION_DEVICE_ACTION_SEND_MESSAGE);

  const sendMessageSubmit = async (values, formik) => {
    formik.setSubmitting(true);

    let sendMessagePayload = {
      device_uuids: [],
      message: values.message,
    };

    sendMessagePayload.device_uuids = device_uuids;
    const response = await sendMessageMutation({
      variables: {
        input: sendMessagePayload,
      },
      onError: (data) => {
        formik.setSubmitting(false);
        throw data;
      },
    });
    formik.setSubmitting(false);
    return response;
  };

  return {
    sendMessageSubmit: sendMessageSubmit,
  };
};
