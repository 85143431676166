import "./style.css";
import { NavLink } from "react-router-dom";

export const MGTabNavItem = ({ route, children }) => {
  return (
    <li className="--mg-tab-nav-item">
      <NavLink to={route ?? ""}>{children}</NavLink>
    </li>
  );
};

MGTabNavItem.propTypes = {};

MGTabNavItem.defaultProps = {};

export default MGTabNavItem;
