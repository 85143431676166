import classNames from "classnames";
import "./style.css";
import PropTypes from "prop-types";

export const MGTabs = ({ children, size }) => {
  var styleNames = {
    "--mg-tabs__sm": size == "sm",
    "--mg-tabs__md": size == "md",
    "--mg-tabs__lg": size == "lg",
  };
  return <ul className={`--mg-tabs ${classNames(styleNames)}`}>{children}</ul>;
};

MGTabs.propTypes = {
  children: PropTypes.node,
  size: PropTypes.string, //"sm" | "md" | "lg",
};

MGTabs.defaultProps = {};

export default MGTabs;
