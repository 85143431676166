import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { MUTATION_DEVICE_ACTION_KICK_MDM } from "@/app/gql/devices/device_actions_mutations";
import { useTranslation } from "react-i18next";
import useMGMutation from "@/app/utils/gql/useMGMutation";

export const useDeviceActionKickMDM = () => {
  const [notification] = useNotification();

  const { t } = useTranslation();

  const [deviceActionKickMDMMutation] = useMGMutation(MUTATION_DEVICE_ACTION_KICK_MDM);
  const deviceActionKickMDM = async (devices) => {
    const { data } = await deviceActionKickMDMMutation({
      variables: {
        input: {
          device_uuids: Array.isArray(devices) ? devices.map((d) => d.device_uuid) : devices,
        },
      },
    });

    if (data && data.deviceActionKickMDM) {
      let { data: response, errors } = data.deviceActionKickMDM;
      if (errors) {
        notification.error({
          message:
            devices.length > 1
              ? t("Failed to kick MDM for {{%s}} devices", { "%s": devices.length })
              : t("Failed to kick MDM for device"),
        });
      }

      if (response.handled) {
        notification.success({
          message:
            response.handled.length > 1
              ? t("Kick MDM command sent to {{%s}} devices", { "%s": response.handled.length })
              : t("Kick MDM command sent to device"),
          tiny: true,
        });
      }
    }
  };

  return [deviceActionKickMDM];
};
