import { PushContentModal } from "../../components/PushContentModal";
import { usePushContentModalContainer } from "../usePushContentModalContainer";
import { useEffect, useState } from "react";

export const PushContentModalContainer = ({
  device_uuids,
  classId,
  sessionId,
  classModeId,

  onPushContentSuccess,
  onPushContentFailure,
  onCancel,
}) => {
  const [showingModeContent, setShowingModeContent] = useState(classModeId !== null);

  const [classModeDataResponse, setClassModeDataResponse] = useState(null);

  const data = usePushContentModalContainer({ classId, classModeId, sessionId });

  const { classContent, classContentLoading, pushContentSubmit, pushModeContentSubmit, getClassModeContentData } = data;

  const onSubmitHandler = async (values, formik) => {
    try {
      var response = null;

      if (showingModeContent) {
        response = await pushModeContentSubmit(values, formik);
      } else {
        response = await pushContentSubmit(values, formik);
      }

      if (typeof onPushContentSuccess === "function") onPushContentSuccess(response);
    } catch (e) {
      onPushContentFailureHandler(e);
    }
  };

  const onPushContentFailureHandler = (err) => {
    if (typeof onPushContentFailure === "function") onPushContentFailure(err);
  };

  const onCancelHandler = () => {
    if (typeof onCancel === "function") onCancel();
  };

  useEffect(async () => {
    if (classModeId) {
      const data = await getClassModeContentData({ classModeId });

      if (data) {
        setClassModeDataResponse(data);
      }
    }
  }, [classModeId]);

  return (
    <>
      {classModeId && (
        <PushContentModal
          isShowing={device_uuids && device_uuids.length > 0}
          classModeId={classModeId}
          contentItems={
            showingModeContent
              ? classModeDataResponse?.data?.classModeContents?.edges?.map(classContentMapper)
              : classContent.map(classContentMapper)
          }
          classContentLoading={classContentLoading || classModeDataResponse?.loading}
          initialValues={{ device_uuids: device_uuids }}
          onSubmit={onSubmitHandler}
          onClose={onCancelHandler}
          showingModeContent={showingModeContent}
          onSwitchContentButtonClick={(value) => {
            setShowingModeContent(value);
          }}
        />
      )}
      {!classModeId && (
        <PushContentModal
          isShowing={device_uuids && device_uuids?.length > 0}
          classModeId={classModeId}
          contentItems={classContent && classContent.length > 0 ? classContent.map(classContentMapper) : []}
          classContentLoading={classContentLoading}
          initialValues={{ device_uuids: device_uuids }}
          onSubmit={onSubmitHandler}
          onClose={onCancelHandler}
          showingModeContent={false}
          onSwitchContentButtonClick={null}
        />
      )}
    </>
  );
};

const classContentMapper = ({ node }) => {
  let detail = node.meta_url;

  return {
    contentId: node.id,
    name: node.name,
    detail: detail,
  };
};
