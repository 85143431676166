import { DeviceFragments, DeviceInstalledApplicationsFragments } from "@/app/gql/devices/device_fragments";
import { gql } from "@apollo/client";

export const GET_DEVICES = gql`
  query devices($limit: Int!, $page: Int!, $q: String) {
    devices(limit: $limit, page: $page, q: $q) {
      __typename
      ... on DeviceConnection {
        edges {
          node {
            ...DeviceBaseParts
            device_statistics {
              ...DeviceStatisticsBaseParts
            }
            computed {
              ...DeviceComputedParts
            }
            device_configuration {
              ...DeviceConfigurationBaseParts
            }
            device_active_profile {
              name
            }
            tags {
              key
              value
            }
            device_students {
              f_name
              l_name
            }
            pending_mdm_commands {
              counter
            }
            device_group {
              name
            }
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages

          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${DeviceFragments.base}

  ${DeviceFragments.device_statistics}
  ${DeviceFragments.device_configuration}
  ${DeviceFragments.computed}
`;

export const GET_DEVICE_BY_ID = gql`
  query device($deviceUUID: ID!) {
    device(deviceUUID: $deviceUUID) {
      __typename
      ... on Device {
        ...DeviceBaseParts
        device_statistics {
          ...DeviceStatisticsBaseParts
        }
        computed {
          ...DeviceComputedParts
        }
        device_configuration {
          device_uuid
          device_group_id
          unlock_pin
          sync_update_interval
          update_interval_battery_ok
          update_interval_battery_low
          sim_swap_policy
          disable_message_tracking
          disable_location_tracking
          created_at
          updated_at
          is_school_owned_device
          is_provisioned
          is_disabled
          os_version_update
          os_version_check_at
          is_classroom
          bluetooth_enabled
          ignore_sam
          sync_chrome_apps
          vacation_mode
        }
        device_active_profile {
          ...DeviceActiveProfileParts
        }
        device_active_profiles {
          ...DeviceActiveProfileParts
        }
        tags {
          key
          value
        }
        device_students {
          f_name
          l_name
        }
        imei
        serial
        last_seen
        client_version
        manufacturer
        device_profiles {
          profile_details {
            name
            description
          }
        }
        device_history {
          type
          description
          ts
          method
          object_id
          object_value
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${DeviceFragments.base}
  ${DeviceFragments.device_statistics}
  ${DeviceFragments.computed}
  ${DeviceFragments.active_profile}
`;

export const GET_DEVICE_INSTALLED_APPLICATIONS = gql`
  query deviceInstalledApplications($deviceUUID: ID!, $page: Int, $limit: Int) {
    deviceInstalledApplications(deviceUUID: $deviceUUID, page: $page, limit: $limit) {
      __typename
      ... on DeviceInstalledApplicationConnection {
        edges {
          node {
            ...DeviceInstalledApplicationBaseParts
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages

          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${DeviceInstalledApplicationsFragments.installed_applications}
`;

export const GET_DEVICE_PUSH_MESSAGES = gql`
  query devicePushMessages($deviceUUID: ID!, $page: Int, $limit: Int) {
    devicePushMessages(deviceUUID: $deviceUUID, page: $page, limit: $limit) {
      __typename
      ... on DeviceAPNsMessageConnection {
        edges {
          node {
            id
            school_id
            user_id
            device_uuid
            student_id
            type
            data
            status
            message
            created_at
            updated_at
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages

          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const GET_DEVICE_WEBFILTER_HISTORY = gql`
  query deviceWebfilterHistories($deviceUUID: ID!, $from: String!, $to: String, $q: String, $page: Int, $limit: Int) {
    deviceWebfilterHistories(deviceUUID: $deviceUUID, from: $from, to: $to, q: $q, page: $page, limit: $limit) {
      __typename
      ... on DeviceWebfilterHistoryConnection {
        edges {
          node {
            id
            device_signature
            profile_uuid
            uri
            was_allowed
            config
            additional_information
            created_at
            updated_at
            uri_base
            uri_search_term
            matched_profile_uuid
            matched_category
            profile_name
            profile_school_id
            was_allowed
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages

          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

/**
 * Gets List of devices available to be assigned to a student
 */
export const DEVICES_FILTER_PERMISSION_LINK_STUDENT_QUERY = gql`
  query devicesFilterPermissionLinkStudent($q: String!) {
    devicesFilterPermissionLinkStudent(q: $q) {
      __typename
      ... on DeviceConnection {
        edges {
          node {
            device_uuid
            device_signature
            device_name
            device_name_actual
            created_at
            updated_at
            manufacturer
            is_enrolled
            is_scep
            has_wallpaper
            is_placeholder
            last_acknowledge
            last_kick
            deleted_at
            phone_number
            is_do_not_disturb_in_effect
            has_mg_app
            has_mg_webfilter
            google_id
            tags {
              key
              value
            }
            device_students {
              id
              f_name
              l_name
            }
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages

          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const QUERY_DEVICES_TO_ADD_TO_CLASS = gql`
  query devicesToAddToClass($q: String, $limit: Int, $page: Int) {
    devicesToAddToClass(q: $q, limit: $limit, page: $page) {
      __typename
      ... on DeviceConnection {
        edges {
          node {
            device_uuid
            device_signature
            device_name
            device_name_actual
            model
            product_name
            jailbroken
            imei
            ip_local
            ip_public
            mac_wifi
            mac_bluetooth
            os_type_id
            os_version
            os_platform
            serial
            last_seen
            is_chrome_managed
            created_at
            updated_at
            school_id
            client_version
            manufacturer
            push_token
            chromebook_android_token
            is_enrolled
            is_scep
            has_wallpaper
            is_placeholder
            last_acknowledge
            last_kick
            deleted_at
            cellular_technology
            is_supervised
            is_device_locator_service_enabled
            phone_number
            is_activation_lock_enabled
            is_do_not_disturb_in_effect
            is_roaming
            personal_hotspot_enabled
            hardware_encryption_caps
            passcode_present
            passcode_compliant
            passcode_compliant_with_profiles
            is_dep
            is_mdm_enroll
            activation_lock_bypass_code
            has_mg_app
            has_mg_webfilter
            is_lostmode
            is_app_locked {
              id
              name
              description
              bundle_name
              icon
            }
            max_resident_users
            google_id
            need_mdm_refresh
            mdm_refresh_in_progress
            is_parent_block_screen
            is_mirrored
            user_is_in_device_group
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const QUERY_DEVICE_LOCATION_HISTORIES = gql`
  query deviceLocationHistories($deviceUUID: ID!, $from: String!, $to: String!, $page: Int, $limit: Int) {
    deviceLocationHistories(deviceUUID: $deviceUUID, from: $from, to: $to, page: $page, limit: $limit) {
      __typename
      ... on DeviceLocationConnection {
        edges {
          node {
            ...DeviceLocationBaseParts
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages
          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
  ${DeviceFragments.location}
`;

export const QUERY_AIRPLAY_DEVICES = gql`
  query airPlayDevices($schoolId: ID!, $limit: Int, $page: Int) {
    airPlayDevices(schoolId: $schoolId, limit: $limit, page: $page) {
      __typename
      ... on ResourceConnection {
        edges {
          node {
            id
            school_id
            file_location
            mime_type
            resource_type
            size
            created_at
            updated_at
            meta_data
            original_filename
            exclude_from_settings
            expiry_date
            deleted_at
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages

          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;

export const QUERY_DEVICE_SINGLE_APP_MODE_APPLICATIONS = gql`
  query deviceSingleModeApplications($os_type_id: Int!, $device_uuid: ID!) {
    deviceSingleModeApplications(device_uuid: $device_uuid, os_type_id: $os_type_id) {
      __typename
      ... on SingleModeApplicationConnection {
        edges {
          node {
            id
            name
            description
            created_at
            updated_at
            bundle_name
            vendor_name
            os_type_id
            external_id
            third_party_browser
            text_while_driving
            is_inappropriate
            is_free
            is_factory
            device_families
            supported_devices
            required_capabilities
            minimum_os_version
            is_32_bit_only
            genres
            icon
          }
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;
