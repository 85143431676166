import { Form, Formik } from "formik";
import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { MGHeading3, MGHelperText } from "@/components/typography";
import { MGButton } from "@/components/buttons";
import * as yup from "yup";
import { MGInputListBoxField } from "@/components/inputs";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useMemo } from "react";
import { QUERY_TAGS } from "@/app/gql/tags/tags_queries";
import useMGQuery from "@/app/utils/gql/useMGQuery";
import { logger } from "@/app/services/logging/Logger";

const RemoveTagsModal = (props) => {
  const { isShowing, initialValues, onClose, onSubmit } = props;

  const { t } = useTranslation();

  const validationSchema = yup.object().shape({
    tags: yup.array().min(1, t("Required")).required(t("Required")),
  });

  const onSubmitHandler = (values, formik) => {
    if (typeof onSubmit === "function") {
      onSubmit(values, formik);
    } else {
      logger.debug("Remove tags: no 'onSubmit' prop provided to Modal, cannot send message.");
    }
  };

  const { data: tagsData, loading: loadingTags } = useMGQuery(QUERY_TAGS);
  const tags = useMemo(() => {
    return tagsData?.tags?.edges ? tagsData.tags.edges.map(({ node }) => ({ ...node })) : [];
  }, [tagsData]);

  return (
    <MGModal isShowing={isShowing}>
      <Formik initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmitHandler}>
        {({ dirty, isSubmitting, isValid, submitForm }) => {
          return (
            <>
              <MGModalHeader>
                <MGHeading3>{t("Remove tags")}</MGHeading3>
              </MGModalHeader>
              <MGModalBody>
                <Form>
                  <MGHelperText>{t("Enter the name of the tag you would like to remove.")}</MGHelperText>
                  <MGInputListBoxField
                    name="tags"
                    placeholder={loadingTags ? t("Loading...") : t("Type tag name")}
                    suggestions={tags}
                    allowFreeText={false}
                    displayProp="value"
                    filter={(value) => {
                      let exactValue = tags.filter((t) => t.value.toLowerCase() == value.toLowerCase());
                      if (exactValue.length == 1) {
                        return exactValue;
                      }
                      return tags.filter((t) => {
                        return t.value.toLowerCase().includes(value.toLowerCase());
                      });
                    }}
                    suggestionRenderer={(tag) => tag.value}
                    displayExpr={(tag) => tag.value}
                  />
                </Form>
              </MGModalBody>
              <MGModalFooter>
                <MGButton variant="tertiary" onClick={onClose}>
                  {t("Cancel")}
                </MGButton>
                <MGButton variant="primary" isLoading={isSubmitting} disabled={!dirty || !isValid} onClick={submitForm}>
                  {t("Remove")}
                </MGButton>
              </MGModalFooter>
            </>
          );
        }}
      </Formik>
    </MGModal>
  );
};

RemoveTagsModal.propTypes = {
  isShowing: PropTypes.bool,
  initialValues: PropTypes.object,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
};

RemoveTagsModal.defaultProps = {};

export default RemoveTagsModal;
