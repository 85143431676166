import { MUTATION_DEVICE_ACTION_SINGLE_APP_MODE } from "@/app/gql/devices/device_actions_mutations";
import useMGMutation from "@/app/utils/gql/useMGMutation";
import { useDeviceActionKickMDM } from "../../hooks/useDeviceActionKickMDM";

export const useSingleAppModeMutation = ({ device_uuid }) => {
  const [singleAppModeMutation] = useMGMutation(MUTATION_DEVICE_ACTION_SINGLE_APP_MODE);
  const [deviceActionKickMDM] = useDeviceActionKickMDM();

  const singleAppModeSubmit = async (values, formik) => {
    deviceActionKickMDM(device_uuid);
    formik.setSubmitting(true);

    let singleAppModePayload = {
      device_uuids: "",
      bundle_id: "",
    };

    singleAppModePayload.device_uuids = device_uuid;
    singleAppModePayload.bundle_id = values.bundle_id;

    const response = await singleAppModeMutation({
      variables: {
        input: singleAppModePayload,
      },
      onError: (data) => {
        formik.setSubmitting(false);
        throw data;
      },
    });
    formik.setSubmitting(false);
    return response;
  };

  return {
    singleAppModeSubmit: singleAppModeSubmit,
  };
};
