import { MGModal, MGModalBody, MGModalFooter, MGModalHeader } from "@/components/modals";
import { useTranslation } from "react-i18next";
import { MGHeading3 } from "@/components/typography";
import { MGButton } from "@/components/buttons";
import { MGRadioField, MGRadioGroupField } from "@/components/controls";
import { Form, Formik } from "formik";
import * as yup from "yup";

export const EyesUpModal = ({ isShowing, eyesUpDevices, eyesDownDevices, eyesUpLoading, onClose, onSuccess }) => {
  const { t } = useTranslation();

  const onCloseHandler = () => {
    if (typeof onClose === "function") onClose();
  };

  const onSuccessHandler = (values) => {
    if (typeof onSuccess === "function") {
      onSuccess(values.eyesUpStatus);
    }
  };

  const validationSchema = yup.object({
    eyesUpStatus: yup.string().matches(/(ON|OFF)/, "Invalid eyes up status selected."),
  });

  return (
    <>
      <MGModal isShowing={isShowing} minWidth={400}>
        <MGModalHeader>
          <MGHeading3>{t("Eyes Up")}</MGHeading3>
        </MGModalHeader>
        <Formik
          onSubmit={onSuccessHandler}
          initialValues={{ eyesUpStatus: eyesUpDevices?.length < eyesDownDevices?.length ? "ON" : "OFF" }}
          validationSchema={validationSchema}
        >
          {() => {
            return (
              <>
                <Form noValidate autoComplete={"off"}>
                  <MGModalBody>
                    <center>
                      {eyesUpDevices != null && eyesUpDevices.length > 0 && (
                        <>{t("There are currently {{%s}} device(s) in Eyes Up.", { "%s": eyesUpDevices?.length })}</>
                      )}
                    </center>

                    <br />
                    <MGRadioGroupField name="eyesUpStatus" asList>
                      <MGRadioField value="ON" name={"eyesUpStatus"}>
                        {t("Enable")}
                      </MGRadioField>
                      <MGRadioField value="OFF" name={"eyesUpStatus"}>
                        {t("Disable")}
                      </MGRadioField>
                    </MGRadioGroupField>
                  </MGModalBody>
                  <MGModalFooter>
                    <MGButton variant="tertiary" onClick={onCloseHandler}>
                      {t("Cancel")}
                    </MGButton>
                    <MGButton variant="primary" type={"submit"} isLoading={eyesUpLoading} disabled={eyesUpLoading}>
                      {t("Apply")}
                    </MGButton>
                  </MGModalFooter>
                </Form>
              </>
            );
          }}
        </Formik>
      </MGModal>
    </>
  );
};

export default EyesUpModal;
