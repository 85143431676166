import { useLazyQuery } from "@apollo/client";
import findGQLProblem from "./findGQLProblem";
import i18next from "i18next";
import { useNotification } from "@/components/notification/MGNotificationProvider/useNotification";
import { authService } from "@/main";

export const useMGLazyQuery = (query) => {
  const [queryPromise, options] = useLazyQuery(query);
  const [notification] = useNotification();

  const onCompletedHandler = (gqlResponse) => {
    if (options && options.onCompleted && typeof options.onCompleted === "function") options.onCompleted(gqlResponse);
    return {
      ...gqlResponse,
      problems: findGQLProblem(gqlResponse?.data),
    };
  };

  const onErrorHandler = (gqlResponse) => {
    if (options && options.onError && typeof options.onError === "function") options.onError(gqlResponse);
    return {
      ...gqlResponse,
      problems: findGQLProblem(gqlResponse?.data),
    };
  };

  const wrappedQueryPromise = async (mutationOptions) => {
    await authService.checkAndRefreshToken(() => {
      //If refresh token fails, this will be called
      notification.warning({ message: i18next.t("Your session has timed out, you will be logged out shortly") });
    });

    return queryPromise(mutationOptions).then(onCompletedHandler, onErrorHandler);
  };

  return [wrappedQueryPromise, options];
};

export default useMGLazyQuery;
