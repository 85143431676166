import PropTypes from "prop-types";
import AddTagsModal from "../components/AddTagsModal";
import { useAddTagsMutation } from "./useAddTagsMutation";

export const AddTagsModalContainer = ({ devices, onAddTagsSuccess, onAddTagsFailure, onAddTagsCancel }) => {
  const { deviceActionAddTags } = useAddTagsMutation({
    devices,
  });

  const onSubmitHandler = async (values) => {
    try {
      await deviceActionAddTags(devices, values.tags);
      if (typeof onAddTagsSuccess === "function") onAddTagsSuccess();
    } catch (e) {
      onAddTagsFailureHandler(e);
    }
  };

  const onAddTagsFailureHandler = (err) => {
    if (typeof onAddTagsFailure === "function") onAddTagsFailure(err);
  };

  const onAddTagsCancelHandler = () => {
    if (typeof onAddTagsCancel === "function") onAddTagsCancel();
  };

  return (
    <>
      <AddTagsModal
        isShowing={devices && devices.length > 0}
        initialValues={{
          tags: "",
        }}
        onClose={onAddTagsCancelHandler}
        onSubmit={onSubmitHandler}
      />
    </>
  );
};

AddTagsModalContainer.propTypes = {
  devices: PropTypes.array,
  onAddTagsSuccess: PropTypes.func,
  onAddTagsFailure: PropTypes.func,
  onAddTagsCancel: PropTypes.func,
};
