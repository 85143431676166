import { gql } from "@apollo/client";

export const CLASS_CONTENTS_ACTIVE_CLASS_QUERY = gql`
  query classContentsActiveClass($classId: ID!, $sessionId: ID!, $limit: Int, $page: Int, $q: String) {
    classContentsActiveClass(classId: $classId, sessionId: $sessionId, limit: $limit, page: $page, q: $q) {
      __typename
      ... on ClassContentConnection {
        edges {
          node {
            id
            name
            description
            object_type
            object_content
            content_type
            meta_url
          }
        }
        pageInfo {
          totalCount
          count
          perPage
          currentPage
          totalPages

          hasNextPage
          hasPreviousPage
        }
      }
      ... on NotQuiteSureProblem {
        message
      }
      ... on Problem {
        message
      }
    }
  }
`;
